class Publisher {
	constructor(API) {
		this.API = API;
		this.apiPath = process.env.PUBLISHER_API_URL;
	}

	fetchPublisher = (publisherId) => {
		const url = `${this.apiPath}/${publisherId}`;
		return this.API.httpGet(url);
	}

	fetchPublisherBranches = (publisherId) => {
		const url = `${this.apiPath}/branch/list/${publisherId}`;
		return this.API.httpGet(url);
	}

	fetchPublisherEmployees = (publisherId) => {
		const url = `${this.apiPath}/employee/list/${publisherId}`;
		return this.API.httpGet(url);
	}

	addPublisherEmployee = (publisherId, employeeType) => {
    const url = `${this.apiPath}/employee/create`;
    const params = {
      key: this.API.getToken(),
			publisherId,
			employeeType
    };
    return this.API.httpPost(url, params);
  }

	togglePublisherEmployeeValid = (publisherId, employeePk, employeeType, toValid) => {
    const url = `${this.apiPath}/employee/auth`;
    const params = {
      key: this.API.getToken(),
			publisherId,
      employeePk,
			employeeType,
			toValid
    };
    return this.API.httpPost(url, params);
  }

	removePublisherEmployee = (publisherId, employeePk) => {
    const url = `${this.apiPath}/employee/auth`;
    const params = {
      key: this.API.getToken(),
			publisherId,
      employeePk,
    };
    return this.API.httpDelete(url, params);
  }

	signupPublisher = (signup) => {
    const url = `${this.apiPath}/signup`;
    const params = {
      key: this.API.getToken(),
			signup,
    };
    return this.API.httpPost(url, params);
  }
}

export default Publisher;
