import React, { useEffect, lazy, Suspense } from 'react';
import loadable from '@loadable/component'
import { Switch, Route, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useStores } from 'stores';
import { Helmet } from "react-helmet";
import history from 'utils/history';
import helpers from 'utils/helpers';

const PartialLoading = loadable(() => import('./PartialLoading'));
const PartialHeader = loadable(() => import('./PartialHeader'));
const PartialShopPanel = loadable(() => import('./PartialShopPanel'));
const PartialMain = loadable(() => import('./PartialMain'));
const PartialFooter = loadable(() => import('./PartialFooter'));
const PartialToast = loadable(() => import('./PartialToast'));
const DrawerNav = loadable(() => import('components/DrawerNav'));
const DrawerMenu = loadable(() => import('components/DrawerMenu'));
const DrawerPublisher = loadable(() => import('components/DrawerPublisher'));
const DrawerPublisherMenu = loadable(() => import('components/DrawerPublisherMenu'));

//Lazy loading pages
const PageHome = lazy(() => import('containers/PageHome'));
const PagePolicy = lazy(() => import('containers/PagePolicy'));
const PagePublisherNews = lazy(() => import('containers/PagePublisherNews'));
const PagePublisherFinance = lazy(() => import('containers/PagePublisherFinance'));
const PagePublisherSettings = lazy(() => import('containers/PagePublisherSettings'));
const PagePublisherContract = lazy(() => import('containers/PagePublisherContract'));
const PageEmployeeAuthRequest = lazy(() => import('containers/PageEmployeeAuthRequest'));
const PagePublisherSignup = lazy(() => import('containers/PagePublisherSignup'));
const PageEditorSignup = lazy(() => import('containers/PageEditorSignup'));
const PagePublisherBusinessGuide = lazy(() => import('containers/PagePublisherBusinessGuide'));
const PagePublisher = lazy(() => import('containers/PagePublisher'))
const PagePress = lazy(() => import('containers/PagePress'))
const PagePressEdit = lazy(() => import('containers/PagePressEdit'))
const PageReleaseProcess = lazy(() => import('containers/PageReleaseProcess'))
const PageReleaseCalculator = lazy(() => import('containers/PageReleaseCalculator'))
const PagePublisherReleaseRecord = lazy(() => import('containers/PagePublisherReleaseRecord'))
const PageTapPay = lazy(() => import('containers/PageTapPay'))
const PageCart = lazy(() => import('containers/PageCart'))
const PageNews = lazy(() => import('containers/PageNews'))
const PageQA = lazy(() => import('containers/PageQA'))
const PageWritingGuide = lazy(() => import('containers/PageWritingGuide'))
const PageNewsSearch = lazy(() => import('containers/PageNewsSearch'))

const PopupMessage = loadable(() => import('Popup/PopupMessage'));
const PopupAction = loadable(() => import('Popup/PopupAction'));
const WidgetLightbox = loadable(() => import('Widgets/WidgetLightbox'));


const App = () => {
	const { mobx } = useStores();
	const location = useLocation();
	const auth = mobx.auth

	useEffect(() => {
		const { queryParamsAct, setInitialized } = mobx.app;
		const { verify } = mobx.user;
		const queryParams = helpers.queryParams();
		if (queryParams.act || queryParams.app) {
			queryParamsAct(queryParams);
			history.replace(history.location.pathname);
		}
		setInitialized();
		if (!window.hotReloading) {
			verify(); //login
		}
	}, [])

	useEffect(() => {
		const { setUI } = mobx.app;
		if (liff.isInClient()) {
			setUI('webview', true)
		}
	}, [])


	return (
		<>
			<Helmet>
				<title>{process.env.APP_TITLE}</title>
				<meta name="description" content={process.env.APP_DESCRIPTION} />
				<meta property="og:url" content={window.location.href} />
				<meta property="og:type" content="website" />
				<meta property="og:title" content={process.env.APP_TITLE} />
				<meta property="og:description" content={process.env.APP_DESCRIPTION} />
				<meta property="og:image" content={`${process.env.CDN_URL}/apr/apr_og_image.png`} />
				<meta property="og:ttl" content="2419200" />
				<link rel="canonical" href={window.location.href} />
			</Helmet>
			<Route component={PartialHeader} />
			<Route>
				<PartialMain>
					<Suspense fallback={<PartialLoading />}>
						<PartialShopPanel />
						<Switch>
							<Route exact path={`/`} component={PageHome} />
							<Route exact path={`/publisher/info/:id/`} component={PagePublisher} />
							<Route exact path={`/publisher/news`} component={PagePublisherNews} />
							<Route exact path={`/publisher/release/record`} component={PagePublisherReleaseRecord} />
							<Route exact path={`/publisher/finance`} component={PagePublisherFinance} />
							<Route exact path={`/publisher/settings/:tab?/:step?`} component={PagePublisherSettings} />
							<Route exact path={`/publisher/contract`} component={PagePublisherContract} />
							<Route exact path={`/employee/auth/:publisher?`} component={PageEmployeeAuthRequest} />
							<Route exact path={`/news`} component={PageNews} />
							<Route exact path={`/news/search/:tab?`} component={PageNewsSearch} />
							<Route exact path={`/press/edit/:step/:id?`} component={PagePressEdit} />
							<Route exact path={`/press/:id`} component={PagePress} />
							<Route exact path={`/release/:id/:step`} component={PageReleaseProcess} />
							<Route exact path={`/release-calculator`} component={PageReleaseCalculator} />
							<Route exact path={`/signup/publisher/:step`} component={PagePublisherSignup} />
							<Route exact path={`/signup/editor/:step`} component={PageEditorSignup} />
							<Route exact path={`/cart`} component={PageCart} />
							<Route exact path={`/tappay`} component={PageTapPay} />
							<Route exact path={`/policy`} component={PagePolicy} />
							<Route exact path={`/questions`} component={PageQA} />
							<Route exact path={`/writing-guide`} component={PageWritingGuide} />
							<Route exact path={`/business`} component={PagePublisherBusinessGuide} />
							<Route path={`*`} component={() => { window.location.href = '/' }} />
						</Switch>
					</Suspense>
				</PartialMain>
			</Route>
			<Route component={DrawerNav} />
			<Route component={DrawerMenu} />
			<Route component={DrawerPublisher} />
			<Route component={DrawerPublisherMenu} />
			<Route component={PartialFooter} />
			<Suspense fallback={<PartialLoading />}>
				<PopupMessage />
				<PopupAction />
				<WidgetLightbox />
				<PartialToast />
			</Suspense>
			<PartialLoading />
		</>
	);
}

export default observer(App);
