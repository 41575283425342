class Release {
  constructor(API) {
    this.API = API;
    this.apiPath = process.env.RELEASE_API_URL;
  }

  releasePress = (publisherId, setting) => {
    const url = `${this.apiPath}/news/`;
    const params = {
      key: this.API.getToken(),
      publisherId,
			setting
    };
    return this.API.httpPost(url, params);
  }

  saveReleaseSetting = (publisherId, setting) => {
    const url = `${this.apiPath}/save`;
    const params = {
      key: this.API.getToken(),
      publisherId,
			setting
    };
    return this.API.httpPost(url, params);
  }

  testReleaseEmail = (setting) => {
    const url = `${this.apiPath}/test`;
    const params = {
      key: this.API.getToken(),
			setting
    };
    return this.API.httpPost(url, params);
  }

  getReleaseRecords = (publisherId, releaseId) => {
    const url = `${this.apiPath}/record/${releaseId || ''}`;
    const params = {
      key: this.API.getToken(),
			publisherId,
    };
    return this.API.httpPost(url, params);
  }
}


export default Release;
