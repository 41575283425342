import { makeAutoObservable } from 'mobx';
import API from 'api';


class AppStore {
	constructor(RootStore) {
		makeAutoObservable(this)
		this.r = RootStore
	};

	taskCounter = 0;
	initialized = false;

	openTask = () => { this.taskCounter = this.taskCounter + 1 };
	closeTask = () => { setTimeout(() => { this.taskCounter = this.taskCounter - 1 }, 250); };

	asyncWorker = async (task) => {
		let result;
		this.openTask();
		try {
			result = await task();
		} catch (error) {
			this.errorHandler(error);
		}
		this.closeTask();
		return result;
	};

	setInitialized = async () => {
		await this.asyncWorker(async () => {
			this.initialized = true;
			//initiate line liff app
			this.initiateLineLiff();
			if (liff.isInClient()) {
				this.r.ui.webview = true
			}
		})
	};

	initiateLineLiff = async (reset) => {
		const lineLogin = () => this.r.user.loginWithLine()
		const liffId = process.env.LINE_LIFF_ID
		console.log('init: ', liffId)
		liff.init({
			liffId: liffId,
			withLoginOnExternalBrowser: false
		}).then(async function () {
			if (!reset) lineLogin()
		}).catch(function (error) {
			console.log(error);
		});
	}

	setUI = (path, value) => {
		let keys = path.split('.');
		let targetKey;
		let parent = this.r.ui;

		if (keys.length === 1) targetKey = keys[0];
		if (keys.length > 1) {
			targetKey = keys.slice(-1)[0];
			keys = keys.slice(0, keys.length - 1);
			keys.forEach(key => parent = parent[key]);
		}

		if (parent && targetKey && parent[targetKey] !== undefined) {
			parent[targetKey] = value;
		}
	};

	toggleUI = (path, value, comparisonKey) => {
		let keys = path.split('.');
		let targetKey;
		let parent = this.r.ui;

		if (keys.length === 1) targetKey = keys[0];
		if (keys.length > 1) {
			targetKey = keys.slice(-1)[0];
			keys = keys.slice(0, keys.length - 1);
			keys.forEach(key => parent = parent[key]);
		}

		if (parent && targetKey && parent[targetKey] !== undefined) {

			if (typeof parent[targetKey] === 'boolean') {
				parent[targetKey] = (typeof value === 'boolean') ? value : !parent[targetKey];
			}

			if (Array.isArray(parent[targetKey])) {
				let found = true;

				if (!!comparisonKey && typeof comparisonKey === 'string') {
					found = parent[targetKey].some(v => v[comparisonKey] === value[comparisonKey])
				} else found = parent[targetKey].some(v => v === value);

				if (found) {
					if (!!comparisonKey && typeof comparisonKey === 'string') {
						parent[targetKey] = parent[targetKey].filter(v => v[comparisonKey] !== value[comparisonKey]);
					} else parent[targetKey] = parent[targetKey].filter(v => v !== value);
				}
				else parent[targetKey].push(value);
			}
		}
	};

	queryParamsAct = (queryParams) => {
		switch (queryParams.act) {
			case 'oauth':
				API.setToken(queryParams.key);
				break;
			case 'oauth-error':
				this.setUI('popup.message', '授權失敗');
				this.setUI('popup.current', 'message');
				break;
			case 'verify-error':
				this.setUI('popup.message', '驗證失敗');
				this.setUI('popup.current', 'message');
				break;
			case 'purchase-success':
				this.setUI('popup.message', '購買成功，謝謝您的支持');
				this.setUI('popup.current', 'message');
				break;
			case 'purchase-error':
				this.setUI('popup.message', '購買失敗，請重新整理確認剩餘數量，或與客服聯繫，我們會盡快提供協助');
				this.setUI('popup.current', 'message');
				break;
			case 'signin':
				API.setToken(queryParams.token);
				break;
		}

		if (queryParams.app === 'webview') {
			this.r.ui.webview = true;
		}
	};

	destroyToast = (id) => {
		this.r.ui.toast.messages = this.r.ui.toast.messages.filter(message => message.id !== id);
	};

	processImage = (url) => {
		return new Promise((resolve, reject) => {
			const img = new Image();
			img.onload = () => {
				resolve({
					width: img.width,
					height: img.height,
					url: url,
				});
			};
			img.onerror = () => {
				reject();
			};
			img.src = url;
		});
	}

	toast = (type, content, callback, action, actionText, actionToCancel) => {
		this.r.ui.toast.messages.forEach(message => {
			const elemToast = document.querySelector(`#${message.id}`);
			if (!elemToast || (elemToast && elemToast.style.display === 'none')) {
				this.destroyToast(message.id);
			}
		});

		const message = {
			id: 'toast_' + Math.random().toString(36).substr(2, 5),
			countdown: false,
			canceled: false,
			type,
			content,
			callback: undefined,
			action: undefined,
			actionText: undefined,
		};

		if (callback) {
			message.callback = () => {
				if (!message.canceled) {
					callback();
				}
			}
		}

		if (action && actionText) {
			message.action = () => {
				if (actionToCancel) {
					const elemToast = document.querySelector(`#${message.id}`);
					message.canceled = true;
					if (elemToast) {
						elemToast.style.setProperty('display', 'none', 'important');
					}
				}
				action();
			}
			message.actionText = actionText;
		}

		this.r.ui.toast.messages.push(message);
	}

	errorHandler = (error) => {
		let errorMessage = ''

		if (process.env.NODE_ENV === 'development') {
			console.error(error);
		}

		switch (error.status) {
			case -2:
				// Token not found
				// ignore this error
				return;
			case -1:
				return;
			case 410: //line provider change
				console.log('line provider error')
				return;
			case 440: //token expired
				API.setToken(undefined);
				errorMessage = '您的登入狀態已逾期，請重新登入';
				break;
			default:
				errorMessage = error.message || '發生未知的錯誤';
		}

		this.setUI('popup.message', errorMessage);
		this.setUI('popup.current', 'message');
	};

	asyncTemplate = async () => {
		await this.asyncWorker(async () => {
		});
	};

	weekdayStrings = ['週一', '週二', '週三', '週四', '週五', '週六', '週日'];
	timeStrings = [
		'00:00:00', '00:30:00', '01:00:00', '01:30:00', '02:00:00', '02:30:00',
		'03:00:00', '03:30:00', '04:00:00', '04:30:00', '05:00:00', '05:30:00',
		'06:00:00', '06:30:00', '07:00:00', '07:30:00', '08:00:00', '08:30:00',
		'09:00:00', '09:30:00', '10:00:00', '10:30:00', '11:00:00', '11:30:00',
		'12:00:00', '12:30:00', '13:00:00', '13:30:00', '14:00:00', '14:30:00',
		'15:00:00', '15:30:00', '16:00:00', '16:30:00', '17:00:00', '17:30:00',
		'18:00:00', '18:30:00', '19:00:00', '19:30:00', '20:00:00', '20:30:00',
		'21:00:00', '21:30:00', '22:00:00', '22:30:00', '23:00:00', '23:30:00',
		'23:59:59',
	];

}

export default AppStore;