class Media {
  constructor(API) {
    this.API = API;
    this.apiPath = process.env.MEDIA_API_URL;
  }

  fetchMedia = (keyword) => {
		const url = `${this.apiPath}${keyword? `/${keyword}` : ''}`;
		return this.API.httpGet(url);
	}

}


export default Media;
