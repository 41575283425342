import { makeAutoObservable } from 'mobx';

class UiState {
  constructor(RootStore) {
    makeAutoObservable(this)
    this.r = RootStore
  };

  webview = false;

	richEditor = {
		paragraphs: []
	}
	
  lightbox = {
    index: -1,
    photos: [],
    active: false,
    editingPhoto: {
      id: 0,
      type: '',
    }
  };

  popup = {
    current: '',
    pending: '',
    title: '',
    message: '',
    callback: null,
  };

  toast = {
    messages: [],
  };

  drawer = {
    menu: {
      active: false,
    },
    publisherMenu: {
      active: false,
    },
    nav: {
      active: false,
    },
		shop: {
			active: false,
		}
  };  
}

export default UiState;