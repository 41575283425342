class Editor {
  constructor(API) {
    this.API = API;
    this.apiPath = process.env.EDITOR_API_URL;
  }

  fetchEditor = (userPk) => {
		const url = `${this.apiPath}/branch/list/${userPk}`;
		return this.API.httpGet(url);
	}

  signupEditor = (signup) => {
    const url = `${this.apiPath}/signup`;
    const params = {
      key: this.API.getToken(),
			signup,
    };
    return this.API.httpPost(url, params);
  }


}


export default Editor;