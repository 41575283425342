import { makeAutoObservable } from 'mobx';
import history from 'utils/history';
import helpers from 'utils/helpers';
import API from 'api';


class UserStore {
	constructor(RootStore) {
		makeAutoObservable(this)
		this.r = RootStore
	};

	signinAtLocalhostAsDeveloper = () => {
		//Development usage
		//localhost
		if (window.location.hostname == 'localhost') {
			API.setToken('17dcefb0-2f92-11ee-a556-bfd625da3a9d')
		}
		//Development usage
	}

	verify = async () => {
		await this.r.app.asyncWorker(async () => {
			const auth = await API.Auth.verify();
			this.setUserAuthState(auth)
			if (auth.publisherId) {
				let publisher = await API.Publisher.fetchPublisher(auth.publisherId);
				const points = await API.Payment.fetchPublisherPoints(auth.publisherId)
				publisher.points = points || 0
				this.setPublisherAuthState(publisher)
			}
		});
	};

	setUserAuthState = (auth) => {
		this.r.auth.user.id = auth.id;
		this.r.auth.user.name = (auth.nickname || '').replace('()', '').replace('(', '（').replace(')', '）');
		this.r.auth.user.avatarUrl = auth.avatar || '';
		this.r.auth.user.email = auth.email;
		this.r.auth.user.phone = auth.phone;
		this.r.auth.authenticated = auth.authenticated === 1;
		this.r.auth.validUser = (!!auth.id && !!auth.authenticated);
		this.r.auth.isPublisher = !!auth.publisherId || false;
		this.r.auth.isEditor = !!auth.isEditor || false;
		if (auth.employee) {
			this.r.auth.publisher.id = auth.publisherId;
			this.r.auth.publisher.employeeId = auth.id;
			this.r.auth.isEmployee = true;
			this.r.auth.user.employeeType = auth.employeeType || 'employee'; //employee || manager
		}
	}

	setPublisherAuthState = (auth) => {
		this.r.auth.publisher.id = auth.publisher_id || '';
		this.r.auth.publisher.name = auth.publisher_name || '';
		this.r.auth.publisher.signed = !!auth.is_signed || false;
		this.r.auth.publisher.company = auth.company_name || '';
		this.r.auth.publisher.chainId = auth.chain_id || '';
		this.r.auth.publisher.taxId = auth.tax_id || '';
		this.r.auth.publisher.email = auth.email || '';
		this.r.auth.financial.creditCard.lastFour = auth.last_four || '';
		this.r.auth.financial.points = auth.points || 0
		this.r.auth.validPublisher = (!!auth.publisher_id && !!auth.is_signed) || false;
	}

	signinWithLine = async () => {
		await this.r.app.asyncWorker(async () => {
			if (liff.isInClient()) {
				this.loginWithLine()
			} else {
				let currentUrl = window.location.href 
				liff.login({ redirectUri: currentUrl })
			}
		});
	};

	checkLineLogin = (message) => {
		this.r.ui.popup.message = message;
		this.r.ui.popup.callback = () => {
			this.signinWithLine();
		}
		this.r.ui.popup.current = 'action';
	}

	loginWithLine = async () => {
		await this.r.app.asyncWorker(async () => {
			//liff.init() automatically calls liff.login()
			if (liff.isLoggedIn()) {
				const lineUserToken = await liff.getDecodedIDToken();
				if (lineUserToken) {
					const userData = {
						email: lineUserToken.email,
						userId: lineUserToken.sub, //line user id is related to provider
						nickname: lineUserToken.name,
						pictureUrl: lineUserToken.picture,
						channelId: lineUserToken.aud //line provider channel 
					}
					const token = await API.Auth.signinWithLineLiff(userData);
					if (token) {
						API.setToken(token);
						await this.verify();
					}
				}
			}
		});
	}

	resetLineLogin = (message) => {
		this.r.ui.popup.message = message;
		this.r.ui.popup.callback = async() => {
			if (liff.isLoggedIn()) {
				liff.logout();
			}
			API.setToken(undefined);
			const origineUrl = window.location.href
			const liffUrl = await liff.permanentLink.createUrlBy(origineUrl)
			window.location.replace(liffUrl);
		}
		this.r.ui.popup.current = 'action';
	}

	signout = async () => {
		await this.r.app.asyncWorker(async () => {
			const logout = await API.Auth.logout();
			API.setToken(undefined);
			if (logout) {
				const origineUrl = window.location.href
				const liffUrl = await liff.permanentLink.createUrlBy(origineUrl)
				if (liff.isLoggedIn()) {
					liff.logout();
				}
				window.location.href = liffUrl; 
			}
		});
	};

}

export default UserStore;