class News {
  constructor(API) {
    this.API = API;
    this.apiPath = process.env.NEWS_API_URL;
  }

  fetchFeaturedNews = async (queryString) => {
    const url = `${this.apiPath}/list${queryString}`;
    return this.API.httpGet(url);
  }

	fetchRelatedNews = async (pressId) => {
    const url = `${this.apiPath}/related/${pressId}`;
    return this.API.httpGet(url);
	}

  uploadNewsPhoto = (base64) => {
    const url = `${this.apiPath}/draft/image`;
    const params = {
      key: this.API.getToken(),
      image: base64,
    };
    return this.API.httpPost(url, params);
  }

  fetchNewsDrafts = (publisherId) => {
    const url = `${this.apiPath}/draft/list`;
    const params = {
      key: this.API.getToken(),
      publisherId
    };
    return this.API.httpPost(url, params);
  }

  fetchNewsDraft = (pressId, publisherId) => {
    const url = `${this.apiPath}/draft/load`;
    const params = {
      key: this.API.getToken(),
      pressId: pressId,
      publisherId
    };
    return this.API.httpPost(url, params);
  }

  updateNewsDraft = (press) => {
    const url = `${this.apiPath}/draft/save`;
    const params = {
      key: this.API.getToken(),
      press
    };
    return this.API.httpPost(url, params);
  }

  deletePress = (pressId) => {
    const url = `${this.apiPath}/draft/delete`;
    const params = {
      key: this.API.getToken(),
      pressId: pressId,
    };
    return this.API.httpDelete(url, params);
  }

  fetchPublisherNews = (publisherId, isAdmin) => {
    const url = `${this.apiPath}/publisher`;
    const params = {
      key: this.API.getToken(),
      publisherId,
      isAdmin,
    };
    return this.API.httpPost(url, params);
  }

  fetchPress = (pressId) => {
    const url = `${this.apiPath}/read/${pressId}`;
    return this.API.httpGet(url);
  }

	searchRelatedTag = (tagName) => {
    const url = `${this.apiPath}/tags/${tagName}`;
    return this.API.httpGet(url);
  }

  generatePressAiDraft = (publisherId, newsInfo) => {
    const url = `${this.apiPath}/generator/create`;
    const params = {
      key: this.API.getToken(),
      publisherId,
      newsInfo
    };
    return this.API.httpPost(url, params);
  }
}

export default News;
