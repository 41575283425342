class Auth {
	constructor(API) {
		this.API = API;
		this.apiPath = process.env.AUTH_API_URL;
	}

	verify = () => {
		const token = window.localStorage.getItem('apr_token');
		if (token) {
			const url = `${this.apiPath}/verify`;
			const params = { 
				key: token 
			};
			return this.API.httpPost(url, params);
		}
		else {
			throw { status: -2 };
		}
	}

	signinWithLineLiff = (user) => {
		const url = `${this.apiPath}/login/line`;
		const params = { user };
		return this.API.httpPost(url, params);
	}

	logout = () => {
		const url = `${this.apiPath}/logout`;
		const params = {
			key: this.API.getToken(),
		};
		return this.API.httpPost(url, params);
	}
}

export default Auth;
