import { makeAutoObservable } from 'mobx';
import history from 'utils/history';
import helpers from 'utils/helpers';
import API from 'api';


class MediaStore {
	constructor(RootStore) {
		makeAutoObservable(this)
		this.r = RootStore
	};

	searchMedia = async (name) => {
    return await this.r.app.asyncWorker(async () => {
      let media = await API.Media.fetchMedia(name);
      media = media
        ? media.map(related => ({
          id: related.id,
          name: related.name || '',
          weight: related.weight || 1,
        }))
        : [];
			return media;
    });
  };

}

export default MediaStore;