import { makeAutoObservable } from 'mobx';
import history from 'utils/history';
import helpers from 'utils/helpers';
import API from 'api';


class newsStore {
	constructor(RootStore) {
		makeAutoObservable(this)
		this.r = RootStore
	};

	categories = ['新聞', '餐飲', '藝術', '文化', '生活', '科技', '健康', '娛樂', '精品'];
	press = null;
	drafts = [];
  news = [];

	adjustNewsCarouselsInterface = async (press) => {
		let carousels = press.map(featuredNews => ({
			id: featuredNews.id || 0,
			title: featuredNews.title || '',
			cover: featuredNews.coverUrl || '',
			coverMobile: featuredNews.coverUrl || '',
			content: featuredNews.summary.slice(0, 60) + '......' || '',
			url: featuredNews.url || '',
		}))
		return carousels || [];
	};

	fetchFeaturedNews = async (page, tag) => {
		return await this.r.app.asyncWorker(async () => {
			let queryString = '';
			let keyValues = ['sort=time'];

			if (page > 1) {
				keyValues.push(`page=${page}`);
			}

			if (tag) keyValues.push(`tag=${tag}`);
			keyValues.push(`domain=${process.env.DOMAIN}`);

			queryString = `?${keyValues.join('&')}`;

			let news = await API.News.fetchFeaturedNews(queryString);
			news = news
				? news.map(featuredNews => ({
					id: featuredNews.id,
					title: featuredNews.title || '',
					publisherId: featuredNews.publisher_id || '',
					publisherName: featuredNews.publisher_name || '',
					publishedTime: featuredNews.publish_timestamp && helpers.parseDate(featuredNews.publish_timestamp * 1000),
					updatedTime: featuredNews.updated_timestamp && helpers.parseDate(featuredNews.updated_timestamp * 1000),
					coverUrl: featuredNews.cover,
					summary: featuredNews.summary,
					category: featuredNews.category,
					url: this.generatePressUrl(featuredNews.id),
				}))
				: [];
			return news;
		});
	};

	fetchRelatedNews = async (pressId) => {
		return await this.r.app.asyncWorker(async () => {
			let news = await API.News.fetchRelatedNews(pressId);
			news = news
				? news.map(featuredNews => ({
					id: featuredNews.id,
					title: featuredNews.title || '',
					publisherId: featuredNews.publisher_id || '',
					publisherName: featuredNews.publisher_name || '',
					publishedTime: featuredNews.publish_time ? helpers.parseDate(featuredNews.publish_time) : '',
					summary: featuredNews.summary || '',
					coverUrl: featuredNews.thumbnail,
					url: this.generatePressUrl(featuredNews.id),
				}))
				: [];

			return news;
		});
	};

	fetchNewsDrafts = async (publisherId) => {
		return await this.r.app.asyncWorker(async () => {
			let drafts = await API.News.fetchNewsDrafts(publisherId);
			drafts = drafts
				? drafts.map(draft => ({
					id: draft.id || 0,
					title: draft.title || '',
					createdAt: draft.created_time && helpers.parseDate(draft.created_time, '+00:00'),
					updatedTime: draft.updated_time && helpers.parseDate(draft.updated_time, '+00:00'),
				}))
				: [];
			this.drafts = drafts;
			return drafts
		});
	};

	fetchNewsDraft = async (pressId, publisherId) => {
		return await this.r.app.asyncWorker(async () => {
			let draft = await API.News.fetchNewsDraft(pressId, publisherId);
			draft = draft ? {
				id: draft.id || '',
				title: draft.title || '',
				link: draft.link || '',
				tags: draft.tags || [],
				link: draft.link || '',
				paragraphs: draft.content || [],
				coverUrl: draft.cover || '',
				thumbUrl: draft.thumbnail || '',
				summary: draft.summary || '',
				published: !!draft.piublish_status || 0,
			} : null;
			return draft;
		});
	};

	saveNewsDraft = async (press) => {
		return await this.r.app.asyncWorker(async () => {
			for (let i = 0; i < press.content.length; i++) {
				const paragraph = press.content[i];
				if (paragraph.type === 'image' && paragraph.content.startsWith('data:')) {
					const base64 = paragraph.content;
					const photo = await API.News.uploadNewsPhoto(base64);
					if (press.cover === base64) {
						press.cover = photo
						press.thumbnail = photo
					}
					press.content[i].content = `${photo}\n${photo}`;
				}
			}
			const pressId = await API.News.updateNewsDraft(press);
			return pressId;
		});
	};

	deletePress = async (pressId) => {
		await this.r.app.asyncWorker(async () => {
			await API.News.deletePress(pressId);
		});
	};

	fetchPress = async (pressId) => {
		return await this.r.app.asyncWorker(async () => {
			let press = await API.News.fetchPress(pressId);
			press = press
				? {
					id: press.id || 0,
					title: press.title || '',
					link: press.link || '',
					paragraphs: press.content || [],
					tags: press.tags || [],
					coverUrl: press.cover || '',
					publishedTime: press.publish_time && helpers.parseDate(press.publish_time, '+00:00'),
					updatedTime: press.updated_time && helpers.parseDate(press.updated_time, '+00:00'),
					url: this.generatePressUrl(press.id),
					category: press.category || '',
					summary: press.summary || '',
					ogImage: '',
					ogImageWidth: 0,
					ogImageHeight: 0,
					publisher : {
						name: press.publisher_name || '',
						id: press.publisher_id || ''
					}
				}
				: undefined;
			if (press) {
				if (press.coverUrl) {
					const image = await this.r.app.processImage(press.coverUrl);
					press.ogImage = press.coverUrl;
					press.ogImageWidth = image.width;
					press.ogImageHeight = image.height;
				}
			}
			return press;
		});
	};

	fetchPublisherNews = async (publisherId, isAdmin) => {
    return await this.r.app.asyncWorker(async () => {
      let news = await API.News.fetchPublisherNews(publisherId, isAdmin);
      news = news
        ? news.map(press => ({
          id: press.id || 0,
          cover: press.thumbnail || '',
          title: press.title || '',
          summary: press.summary || '',
					isPublished: !!press.publish_status || 0,
					isReleased: !!press.release_status || 0,
          publishedTime: press.publish_timestamp,
          releaseTime: press.release_time || '',
          updatedTime: press.edit_timestamp,
          url: this.generatePressUrl(press.id),
        }))
        : [];
				return news
    });
  };

	searchRelatedTag = async (tagName) => {
    return await this.r.app.asyncWorker(async () => {
      let relatedTags = await API.News.searchRelatedTag(tagName);
      relatedTags = relatedTags
        ? relatedTags.map(related => ({
          id: related.id,
          name: related.name || '',
        }))
        : [];
			return relatedTags;
    });
  };

	generatePressAiText = async (newsInfo) => {
		return await this.r.app.asyncWorker(async () => {
			const publisherId = this.r.auth.publisher.id
			let draft = await API.News.generatePressAiDraft(publisherId, newsInfo);
			return draft;
		});
	};

	generatePressUrl = (pressId) => {
		const url = `/press/${pressId}`;
		return url;
	}
}

export default newsStore;