import Auth from './Auth';
import Editor from './Editor';
import Media from './Media';
import Publisher from './Publisher';
import News from './News';
import Release from './Release';
import Payment from './Payment';

class API {
  constructor() {
    this.Auth = new Auth(this);
    this.Editor = new Editor(this);
    this.Media = new Media(this);
    this.Publisher = new Publisher(this);
    this.News = new News(this);
    this.Release = new Release(this);
    this.Payment = new Payment(this);
  }

  fetchOptions = {
    headers: { 'content-type': 'application/json' },
  }

  setToken = (token) => {
    if(token) {
      window.localStorage.setItem('apr_token', token);
    }
    else {
      window.localStorage.removeItem('apr_token');
    }
  }

  getToken = () => {
    const token = window.localStorage.getItem('apr_token');
    if(token) return token;
    else throw { status: -1 };
  }

  dataHandler = data => {
    if(data.status === 200) {
      return data.message;
    }
    else {
      throw { 
        status: data.status, 
        message: data.message
      };
    }
  }

  createTimeoutSignal = (timeout) => {
    if(window.AbortController && timeout) {
      const controller = new AbortController();
      const signal = controller.signal;

      setTimeout(() => {
        controller.abort();
      }, timeout);

      return signal;
    }
  }

  httpGet = (url, timeout) => {
    return fetch(url, {
      ...this.fetchOptions,
      method: 'GET',
      signal: this.createTimeoutSignal(timeout),
    })
    .then(resp => resp.json())
    .then(this.dataHandler)
  }

  httpPost = (url, params, timeout) => {
    return fetch(url, {
      ...this.fetchOptions,
      method: 'POST',
      body: JSON.stringify(params),
      signal: this.createTimeoutSignal(timeout),
    })
    .then(resp => resp.json())
    .then(this.dataHandler)
  }

  httpPut = (url, params, timeout) => {
    return fetch(url, {
      ...this.fetchOptions,
      method: 'PUT',
      body: JSON.stringify(params),
      signal: this.createTimeoutSignal(timeout),
    })
    .then(resp => resp.json())
    .then(this.dataHandler)
  }

  httpDelete = (url, params, timeout) => {
    return fetch(url,{
      ...this.fetchOptions,
      method: 'DELETE',
      body: JSON.stringify(params),
      signal: this.createTimeoutSignal(timeout),
    })
    .then(resp => resp.json())
    .then(this.dataHandler)
  }
}



export default new API();
