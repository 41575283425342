import { makeAutoObservable, toJS } from 'mobx';
import history from 'utils/history';
import helpers from 'utils/helpers';
import API from 'api';


class paymentStore {
	constructor(RootStore) {
		makeAutoObservable(this)
		this.r = RootStore
	};

	tappayOrder = null;
	products = null;

	prepareOrder = async (order) => {
		/**
		 * @param {Object} order is An object representing an order for purchase.
		 * {
				email: email,
				promotionCode: '',
				invoice: {
					type: B2B',
					taxId: '',
					taxName: '',
				},
				total: 0,
				method:'CREDIT',
				items: [arr]
			} 
		 * 
		 */
		this.tappayOrder = order;
		history.push('/tappay')
	}

	fetchProducts = async () => {
		return await this.r.app.asyncWorker(async () => {
			let products = await API.Payment.fetchProducts();
			products = products
				? products.map(product => ({
					id: product.id,
					name: product.name,
					price: product.price,
					point: product.point,
					remark: product.remark,
					quantity: 0,
				})) : undefined;
				this.products = products
			return products
		});
	};

	setSelectedQuantity = (quantity, productId) => {
		let idx = this.products.findIndex(item => (item.id === productId));
		let q = Number(quantity);
		this.products[idx].quantity = q;
	}

	checkPromotionCode = async (code) => {
		return await this.r.app.asyncWorker(async () => {
			let promotionCodeRule = await API.Payment.getPromotionCodeRule(code);
			promotionCodeRule = promotionCodeRule
				? {
					value: promotionCodeRule.code,
					type: promotionCodeRule.type || '',
					threshold: promotionCodeRule.threshold || 0,
					discount: promotionCodeRule.discount || 0,
					invalid: false
				} : undefined;
			return promotionCodeRule
		});
	};

	submitTapPayPayment = async (tappayOrder, tappayData) => {
		await this.r.app.asyncWorker(async () => {
			this.r.ui.popup.message = '系統處理中，請稍候。';
			this.r.ui.popup.current = 'message';
			const publisherId = this.r.auth.publisher.id
			const result = await API.Payment.submitTapPayPayment(tappayOrder, tappayData, publisherId);
			//result.paymentId
			if (result.paymentMsg === 'SUCCESS') {
				//post to tappay success api
				if (!!result.threeDomainSecure) {
					window.location.href = result.paymentUrl;
				} else {
					const done = await API.Payment.successTapPayPayment(result.paymentId, tappayOrder?.email)
					if (done) {
						this.r.ui.popup.message = '付款成功'
						this.r.ui.popup.callback = () => history.push('/member/finance/tickets');
						this.r.ui.popup.current = 'message';
						fbq('track', 'Purchase', { value: tappayOrder.total, currency: 'TWD' }, { eventID: tappayData.prime })
					}
				}
			}
		});
	};


	fetchOrder = async (paymentId) => {
		return await this.r.app.asyncWorker(async () => {
			let order = await API.Payment.fetchOrder(paymentId);
			order = order
				? {
					paymentId: order.payment_id || '',
					shopId: order.shop_id || '',
					shopName: order.shop_name || '',
					remark: order.remark || '',
					paidAmt: order.amount || 0,
					createdTime: helpers.getGmtLocalTime(new Date(order.created_time) || '', 8) || '',
					wayPayment: order.payment_method || 'NONE',
					status: order.status || 0,
					billStatus: order.bill_status || '',
					amount: order.amount || '',
					remark: order.remark || '',
					recipientName: order.recipient_name || '',
					recipientPhone: order.recipient_phone || '',
					recipientEmail: order.recipient_email || '',
					invoice: {
						number: order.invoice_number || '',
						status: order.invoice_status || '',
					},
					billId: order.bill_id || '',
					items: order.items
						? order.items.map(item => ({
							cover: item.cover || '',
							productId: item.product_id || '',
							productName: item.product_name || '',
							specificationId: item.specification_id || 0,
							specificationName: item.specification_name || '',
							quantity: item.quantity || 0,
							price: item.price || 0,
						})) : [],
					tickets: order.tickets
						? order.tickets.map(t => ({
							memberTicketId: t.id || '',
							ticketId: t.ticket_id || '',
							name: t.name || '',
							quantity: t.quantity || 0,
							remain: t.remain || 0,
							remark: t.remark || '',
							cover: t.cover || '',
							token: t.token || '',
							isValid: t.is_valid || 0,
							logTime: t.log_time || '',
							startTime: t.start_time || '',
							endTime: t.end_time || '',
						})) : []
				}
				: null;
			return order
		});
	};


	fetchPublisherOrders = async (query, page) => {
		return await this.r.app.asyncWorker(async () => {
			let logs = await API.Payment.fetchPublisherOrders(this.r.auth.publisher.id, query, page);
			logs = logs
				? logs.map(log => ({
					paymentId: log.payment_id || '',
					status: log.status || 0,
					cost: log.amount || '',
					remark: log.remark || '',
					createdTime: helpers.getGmtLocalTime(new Date(log.created_time) || '', 8) || '',
					items: [],
					productName:log.product_name || '',
					productPrice:log.price || '',
					productQuantity:log.quantity || '',
					invoice: {
						number: log.invoice_number || '',
						status: log.invoice_status || '',
					},
				}))
				: null;
			return logs
		});
	};

	downloadPublisherOrders = async (query, page) => {
		return await this.r.app.asyncWorker(async () => {
			let logs = await API.Payment.fetchPublisherOrders(this.r.auth.publisher.id, query, page);
			logs = logs
				? logs.map(log => ({
					'訂單編號': log.payment_id || '--',
					'訂單狀態': log.status || '--',
					'訂單金額': log.amount || '--',
					'成立時間': helpers.getGmtLocalTimeString(new Date(log.created_time) || '', 8, 'YYYY-MM-DD hh:mm:ss') || '--',
					'發票號碼': log.invoice_number || '--',
					'訂單商品': log.item_list || '--',
					'訂單備註': (log.remark || '').replace(/"|,/g, '') || '--',
				}))
				: null;
			return logs
		});
	};
}

export default paymentStore;