
import { createGlobalStyle } from 'styled-components';

export const theme = {
  colors: {
    // Brand colors
    maincolor: '#D85678', // Main CI color
    sencondarycolor: '#FCC7CF', // To emphasize // Secondary Ci color
		aimirucha: '#41B880', // Main CI color
    akebono: '#F19483', // To emphasize
    darkmaincolor: '#E36B89', // hover green
		//ui colors
    silver: '#bec6c6', //background color
    smoke: '#faedef', //shop background color
    platinum: '#b2afad', //light background green
    // Invalid or Error Status
    error: '#A53E3E',
    // OAuth colors
    google: '#b73126',
    facebook: '#3c5a97',
    link: '#4f4fe3',
		line: '#06C755',
    // Other colors 
		gsf2:'#f2f2f2', // background gray
    gse6: '#e6e6e6', // disabled, input background
    gsc3: '#c3c3c3', // border color
    gsa2:'#a2a2a2', // gray font on gray background
    gs83: '#838383', // gray font on white background
    dimGray:'#5c6260', // gray font on silver background
    gs3b: '#3B3B3B', // global style black font color  
    gsbc: '#bcbcbc',
    gsff: '#ffffff',
    gsf5: '#f5f5f5',
    gseb: '#ebebeb',
    gscc: '#cccccc',
    gsb2: '#b2b2b2',
    gs9d: '#9d9d9d',
    gs65: '#656565',
    gs22: '#222222',
    gs00: '#000000',
  },
  dimensions: {
    headerHeight: '4.375rem', //70px
    barHeight: '3.75rem', //60px
    tabBarHeight: '49px',
  },
  effects: {
    borderRadius: '5px',
  }
};

export const GlobalStyle = createGlobalStyle`
  html {
    font-family: 'Noto Sans TC', 'PingFang TC', 'Microsoft JhengHei', sans-serif;
    font-size: 16px;
    line-height:1.5em;
    color: #3B3B3B;
    height: 100%;
    box-sizing: border-box;
    position: relative;
  }

  body {
    min-height: 100%;
    margin: 0;
    display: flex;
    box-sizing: border-box;
    position: relative;
  }

  form {
    appearance: none;
  }

  h1, h2, h3, h4, h5, h6, p, span, pre, a, b {
    font-family: inherit;
    font-size: inherit;
    color: inherit;
    margin: 0;
  }
  
  h1 {
    font-size: 50px;
    //font-weight: medium;
    font-weight: 700;
    line-height:1.5em;
  }

  h2 {
    font-size: 36px;
    //font-weight: medium;
    font-weight: 700;
    line-height:1.5em;
  }

  h3 {
    font-size: 30px;
    //font-weight: medium;
    font-weight: 400;
    line-height:1.5em;
  }

  h4 {
    font-size: 20px;
    //font-weight: regular;
    font-weight: 400;
    line-height:1.5em;
  }

  h5 {
    font-size: 18px;
    //font-weight: regular;
    font-weight: 400;
    line-height:1.5em;
  }

  h6 {
    font-size: 16px;
    //font-weight: regular;
    font-weight: 400;
    line-height:1.5em;
  }

  p, pre {
    font-size:14px;
  }

  small {
    font-size:12px;
		font-weight: 400;
    line-height:1.5em;
  }

  img {
    width:100%;
    display: flex;
    object-fit: cover;
  }

  br, hr {
    margin: 0;
    padding: 0;
  }

  input, textarea {
		line-height: 1.5em;
    border: none;
    outline: none;
  }

  input[type=password]:not(:placeholder-shown) {
    font-family: sans-serif;
    font-size: 1.125rem;
    font-weight: 600;
    letter-spacing: 0.5rem;
  }

  input::-ms-clear,
  input::-ms-reveal,
  input::-webkit-clear-button,
  input::-webkit-inner-spin-button {
    display: none;
  }

  input[type="date"]::-webkit-clear-button,
  input[type="date"]::-webkit-inner-spin-button {
    display: none;
  }

  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0; 
  }
  
  input[type=number] {
      -moz-appearance:textfield; 
  }

  select{
    -moz-appearance: none;
    -webkit-appearance: none;
    text-align-last: center;
    border:0px;
    outline:0px;
  }


  #app-root {
    min-height: 100vh;
    overflow: hidden;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    box-sizing: border-box;
    position: relative;
  }
`;
