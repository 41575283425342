import { makeAutoObservable } from 'mobx';
import history from 'utils/history';
import helpers from 'utils/helpers';
import API from 'api';


class PublisherStore {
	constructor(RootStore) {
		makeAutoObservable(this)
		this.r = RootStore
	};

	fetchPublisher = async (publisherId) => {
		return await this.r.app.asyncWorker(async () => {
			let publisher = await API.Publisher.fetchPublisher(publisherId);
			publisher = {
				id: publisher.publisher_id || '',
				name: publisher.publisher_name || '',
				company: publisher.company_name || '',
				taxId: publisher.tax_id || '',
				taxInvoice: publisher.tax_invoice || 0,
				pixel: publisher.pixel_id || '',
				email: publisher.email || '',
				phone: publisher.phone || '',
				isSigned: publisher.is_signed || 0,
				cardLastFour: publisher.last_four || '',
				cardValid: publisher.card_valid || 0,
			};
			return publisher
		});
	};

	fetchPublisherBranches = async (publisherId) => {
		return await this.r.app.asyncWorker(async () => {
			let branches = await API.Publisher.fetchPublisherBranches(publisherId);
			branches = branches
				? branches.map(branch => ({
					id: branch.publisher_id || '',
					name: branch.publisher_name || '',
					cover: branch.publisher_cover || '',
					url: `/publisher/info/${branch.id}`,
					signed: branch.is_signed || false,
				}))
				: [];
			return branches;
		});
	};

	changeBranch = (branchId, branchName) => {
		this.r.auth.publisher.id = branchId;
		this.r.auth.publisher.branchId = branchId;
		this.r.auth.publisher.name = branchName;
		this.r.auth.publisher.url = `/publisher/info/${branchId}`;
	};

	fetchPublisherEmployees = async (publisherId) => {
		return await this.r.app.asyncWorker(async () => {
			let employees = await API.Publisher.fetchPublisherEmployees(publisherId);
			employees = employees
				? employees.map(employee =>
				({
					name: employee.nickname || '',
					id: employee.employee_pk || '',
					isValid: employee.is_valid || 0,
					lineNotification: employee.line_notification || 0,
					type: employee.type || 'employee',
				})) : null;
			return employees;
		});
	};

	removePublisherEmployee = async (publisherId, employeeId) => {
		return await this.r.app.asyncWorker(async () => {
			await API.Publisher.removePublisherEmployee(publisherId, employeeId);
		});
	};

	addPublisherEmployee = async (publisherId, employeeType, isPublisher) => {
		return await this.r.app.asyncWorker(async () => {
			await API.Publisher.addPublisherEmployee(publisherId, employeeType);
			if (!isPublisher) {
				this.r.ui.popup.message = '已送出請求'
				this.r.ui.popup.callback = () => {
					history.push('/')
					liff.closeWindow()
				};
				this.r.ui.popup.current = 'message';
			}
		});
	};

	togglePublisherEmployeeValid = async (publisherId, employeePk, employeeType, toValid) => {
		return await this.r.app.asyncWorker(async () => {
			await API.Publisher.togglePublisherEmployeeValid(publisherId, employeePk, employeeType, toValid);
		});
	};


}

export default PublisherStore;