import { makeAutoObservable } from 'mobx';
import history from 'utils/history';
import helpers from 'utils/helpers';
import API from 'api';


class ReleaseStore {
	constructor(RootStore) {
		makeAutoObservable(this)
		this.r = RootStore
	};

	saveReleaseSetting = async (publisherId, setting) => {
    return await this.r.app.asyncWorker(async () => {
      const result = await API.Release.saveReleaseSetting(publisherId, setting);
      if(result && !setting.publish){
        this.r.ui.popup.message = '已儲存新聞稿發布設定'
				this.r.ui.popup.current = 'message';
      }
    });
  };

  testReleaseEmail = async (releaseSetting) => {
    return await this.r.app.asyncWorker(async () => {
      const result = await API.Release.testReleaseEmail(releaseSetting);
      if(result){
        this.r.ui.popup.message = '已送出測試新聞稿'
				this.r.ui.popup.current = 'message';
      }
    });
  };

  releasePress = async (publisherId, releaseSetting) => {
    return await this.r.app.asyncWorker(async () => {
      const result = await API.Release.releasePress(publisherId, releaseSetting);
      if(result){
        this.r.ui.popup.message = '已發布新聞稿'
				this.r.ui.popup.callback = () => history.push('/publisher/release/record');
				this.r.ui.popup.current = 'message';        
      }
    });
  };

  getReleaseRecords = async (publisherId) => {
    return await this.r.app.asyncWorker(async () => {
      let result = await API.Release.getReleaseRecords(publisherId);
      result = result
				? result.map(record =>
          ({
            id: record.id || '',
            preddId: record.press_id || '',
            pressTitle: record.title || '',
            mediaList: record.media_list || [],
            mediaNames: record.media_names || [],
            emailContent: record.email_content || null,
            published: record.release_status || 0,
            cost: record.cost || 0,
            createdTime: record.created_time || null,
            updatedTime: record.updated_time || null,
            publishTime: record.release_time || null,
          })) : null;
          return result
    });
  };

  getReleaseRecord = async (publisherId, releaseId) => {
    return await this.r.app.asyncWorker(async () => {
      let result = await API.Release.getReleaseRecords(publisherId, releaseId);
      result = result
				? result.map(record =>
          ({
            id: record.id || '',
            pressId: record.press_id || '',
            pressTitle: record.title || '',
            mediaList: record.media_list || [],
            mediaNames: record.media_names || [],
            emailContent: record.email_content || null,
            published: record.release_status || 0,
            createdTime: record.created_time || null,
            updatedTime: record.updated_time || null,
            publishTime: record.release_time || null,
          })) : null;
          return result[0]
    });
  };
}

export default ReleaseStore;