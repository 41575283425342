import helpers from 'utils/helpers';
import { v1 as uuidv1 } from 'uuid';
import CryptoJS from 'crypto-js';
import AES from 'crypto-js/aes';

class PluginStore {
	constructor(RootStore) {
		this.r = RootStore
	};

	encodeTokenToUrl = async (token, liffId) => {
		try {
			//encode ticket token
			let tokenEncode = CryptoJS.AES.encrypt(
				token,
				"Pirika Pirilala Popolina Peperuto"
			).toString();
			tokenEncode = tokenEncode
				.replace(/[/]/g, "slashmark")
				.replace(/-/g, "－")
				.replace(/%/g, "％")
				.replace(/\?/g, "quationmark");
			let url = liffId ?
				`https://liff.line.me/${liffId}/ticket/${tokenEncode}`
				: `${process.env.PUBLIC_URL}/ticket/${tokenEncode}`;
			return url;
		}
		catch (error) {
			console.log(error)
			throw error
		}
	}

	shareAuthLinkInLine = async (authSource) => {
		liff.shareTargetPicker([{
			"type": "flex",
			"altText": "如未成功顯示，請聯繫客服",
			"contents": {
				"type": "bubble",
				"hero": {
					"type": "image",
					"url": `${process.env.CDN_URL}/taketla/taketla-og.jpg`,
					"size": "full",
					"aspectRatio": "20:13",
					"aspectMode": "cover"
				},
				"body": {
					"type": "box",
					"layout": "vertical",
					"contents": [
						{
							"type": "text",
							"text": `${authSource.usage}：請點選「申請授權」按鈕，經審核後將能夠使用部分功能`,
							"color": "#aaaaaa",
							"wrap": true,
							"margin": "xxl",
							"size": "sm"
						},
						{
							"type": "box",
							"layout": "vertical",
							"margin": "lg",
							"spacing": "sm",
							"contents": [
								{
									"type": "box",
									"layout": "baseline",
									"spacing": "sm",
									"contents": [
										{
											"type": "text",
											"text": "授權公司",
											"color": "#aaaaaa",
											"size": "sm",
											"flex": 1
										},
										{
											"type": "text",
											"text": `${authSource.shopName}`,
											"wrap": true,
											"size": "sm",
											"color": "#666666",
											"flex": 4
										}
									]
								},
								{
									"type": "box",
									"layout": "baseline",
									"spacing": "sm",
									"contents": [
										{
											"type": "text",
											"text": "授權人",
											"color": "#aaaaaa",
											"size": "sm",
											"flex": 1
										},
										{
											"type": "text",
											"text": `${authSource.userName}`,
											"wrap": true,
											"color": "#666666",
											"size": "sm",
											"flex": 4
										}
									]
								}
							]
						}
					]
				},
				"footer": {
					"type": "box",
					"layout": "horizontal",
					"contents": [
						{
							"type": "button",
							"style": "primary",
							"action": {
								"type": "uri",
								"label": "申請授權",
								"uri": authSource.url
							},
							"height": "md",
							"color": "#74716e"
						}
					]
				}
			}
		}], { isMultiple: true, })
			.then((res) => {
				if (res && !liff.isInClient()) {
					this.r.ui.popup.message = '已發送授權連結。';
					this.r.ui.popup.current = 'message';
				}
				liff.closeWindow()
			}).catch((err) => {
				console.log(err)
			});
	}

	sendLineMessage = async (messages) => {
		/**
		 * Line Liff Message
		 */
		if (liff.isInClient()) {
			liff.sendMessages(
				messages.map(message => ({
					"type": "text",
					"text": message
				}))
			).catch((err) => {
				console.log(err)
			});
		}
	}
}

export default PluginStore;