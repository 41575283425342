class PAYMENT {
	constructor(API) {
		this.API = API;
		this.apiPath = process.env.PAYMENT_API_URL;
	}

	fetchProducts = () => {
		const url = `${this.apiPath}/product`;
		return this.API.httpGet(url);
	}

	fetchPublisherPoints = (publisherId) => {
		const url = `${this.apiPath}/payment/points/check`;
		const params = {
			publisherId
		};
		return this.API.httpPost(url, params);
	}

	bindCreditCard = (publisherId, prime, cardHolder, email, phone) => {
		const url = `${this.apiPath}/payment/card/bind`;
		const params = {
			key: this.API.getToken(),
			publisherId, prime, cardHolder, email, phone
		};
		return this.API.httpPost(url, params);
	}

	removeCreditCard = (publisherId, lastFour) => {
		const url = `${this.apiPath}/payment/card/remove`;
		const params = {
			key: this.API.getToken(),
			publisherId, lastFour
		};
		return this.API.httpPost(url, params);
	}

	submitTapPayPayment = async (tappayOrder, tappayData, publisherId) => {
		const url = `${this.apiPath}/payment/purchase/tappay`;
		const params = {
			key: this.API.getToken(),
			tappayOrder,
			tappayData,
			publisherId
		};
		return this.API.httpPost(url, params);
	}

	successTapPayPayment = async (order_number, email) => {
		const url = `${this.apiPath}/payment/purchase/notify`;
		const params = {
			order_number,
			email,
			status: 0
		};
		return this.API.httpPost(url, params);
	}

	fetchOrder = async (paymentId) => {
		const url = `${this.apiPath}/order/${paymentId}`;
		const params = {
			key: this.API.getToken(),
		};
		return this.API.httpPost(url, params);
	}

	fetchPublisherOrders = async (publisherId, query, page) => {
		const url = `${this.apiPath}/order/publisher`;
		const params = {
			key: this.API.getToken(),
			publisherId,
			page,
			query
		};
		return this.API.httpPost(url, params);
	}

	getPromotionCodeRule = async (codeId) => {
		const url = `${this.apiPath}/promotion/check`;
		const params = {
			codeId
		};
		return this.API.httpPost(url, params);
	}

}

export default PAYMENT;
